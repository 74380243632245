<template>
  <div class="page">
    <v-container fluid>
      <avp-breadcrumbs></avp-breadcrumbs>
      <v-row>
        <v-col cols="12" md="12" lg="12" xl="12">
          <v-card rounded="lg">
            <v-tabs v-model="tab" centered>
              <v-tabs-slider></v-tabs-slider>
              <v-tab href="#comprovacao-acao-de-capacitacao">
                <v-icon class="p-2">mdi-account</v-icon>
                Comprovação de Ação de Capacitação
              </v-tab>
            </v-tabs>
            <v-tabs-items v-model="tab">
              <v-tab-item value="comprovacao-acao-de-capacitacao">
                <v-form
                  ref="form"
                  v-model="form.valid"
                  class="px-10"
                  autocomplete="off"
                >
                  <v-card rounded="lg">
                    <v-container>
                      <v-row>
                        <v-col cols="6" class="d-block">
                          <v-text-field
                            label="Ano"
                            hint="Informe o ano da comprovação"
                            v-model="form.ano"
                            readonly
                            outlined
                            dense
                          ></v-text-field>
                        </v-col>
                        <v-col cols="6" class="d-block">
                          <v-text-field
                            label="Semestre"
                            hint="Informe o semestre da comprovação"
                            v-model="form.semestre"
                            outlined
                            readonly
                            dense
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col
                          :cols="form.instituicaoId == 19 ? '3' : '6'"
                          class="d-block"
                        >
                          <v-select
                            label="Nome da Instituição"
                            hint="Informe o nome da instituição"
                            v-model="form.instituicaoId"
                            :items="lists.instituicoes"
                            :item-value="'id'"
                            :item-text="'descricao'"
                            :rules="[(v) => $required(v)]"
                            outlined
                            dense
                          ></v-select>
                        </v-col>
                        <v-col
                          v-if="form.instituicaoId == 19"
                          cols="3"
                          class="d-block"
                        >
                          <v-text-field
                            label="Qual Instituição?"
                            hint="Informe qual a instituição"
                            v-model="form.nomeDeOutraInstituicao"
                            :rules="[(v) => $required(v)]"
                            outlined
                            dense
                          ></v-text-field>
                        </v-col>

                        <v-col cols="6" class="d-block">
                          <v-select
                            label="Tipo da Ação de Capacitação"
                            hint="Informe o tipo da ação de capacitação"
                            v-model="form.tipoCapacitacaoId"
                            :items="lists.tiposCapacitacao"
                            :item-value="'id'"
                            :item-text="'descricao'"
                            :rules="[(v) => $required(v)]"
                            outlined
                            dense
                          ></v-select>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col
                          :cols="lists.trilhasAprendizagem.length ? '6' : '12'"
                          class="d-block"
                        >
                          <v-select
                            label="Eixo Temático"
                            hint="Informe o eixo temático da ação de capacitação"
                            v-model="form.eixoTematicoId"
                            :items="lists.eixosTematicos"
                            :item-value="'id'"
                            :item-text="'descricao'"
                            :rules="[(v) => $required(v)]"
                            @change="
                              setTrilhasAprendizagem(form.eixoTematicoId)
                            "
                            outlined
                            dense
                          ></v-select>
                        </v-col>
                        <v-col
                          v-if="lists.trilhasAprendizagem.length"
                          cols="6"
                          class="d-block"
                        >
                          <v-select
                            label="Trilha de Aprendizagem"
                            hint="Informe a trilha de aprendizagem"
                            v-model="form.trilhaDeAprendizagemId"
                            :items="lists.trilhasAprendizagem"
                            :item-value="'eixoTrilhaId'"
                            :item-text="'descricao'"
                            :rules="[(v) => $required(v)]"
                            outlined
                            dense
                          ></v-select>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" class="d-block">
                          <v-text-field
                            label="Nome da ação de capacitação"
                            hint="Informe o nome da ação de capacitação"
                            v-model="form.nomeDaAcaoDeCapacitacao"
                            counter="255"
                            :rules="[
                              (v) => $min(v, 5),
                              (v) => $max(v, 255),
                              (v) => $onlyTextAndNumbers(v),
                            ]"
                            outlined
                            dense
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="6" class="d-block">
                          <v-select
                            label="Discente ou Docente"
                            hint="Informe se a ação é discente ou docente"
                            v-model="form.tipoDaCondicaoDeCapacitacao"
                            :items="$tipoCondicaoOptions"
                            :item-text="'descricao'"
                            :item-value="'value'"
                            :rules="[(v) => $required(v)]"
                            outlined
                            dense
                          ></v-select>
                        </v-col>
                        <v-col cols="6" class="d-block">
                          <v-text-field
                            label="Carga Horária"
                            hint="Informe a carga horária"
                            v-model="form.cargaHoraria"
                            v-mask="'###'"
                            :rules="[
                              (v) => $required(v),
                              (v) => $minValue(v, 1),
                            ]"
                            outlined
                            dense
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <avp-date-picker
                          label="Dia, Mês e Ano de Início"
                          ref="dataInicioCapacitacao"
                          cols="6"
                          required
                          :dataEdicao="form.dataInicioCapacitacao"
                        ></avp-date-picker>
                        <avp-date-picker
                          label="Dia, Mês e Ano de Conclusão"
                          ref="dataFimCapacitacao"
                          cols="6"
                          required
                          :dataEdicao="form.dataFimCapacitacao"
                        ></avp-date-picker>
                      </v-row>
                      <avp-upload-file
                        :anexo="form.anexo"
                        v-model="form.anexo"
                        :size="8"
                        :offset="2"
                        showInfo
                      ></avp-upload-file>
                      <v-row>
                        <v-col offset="2" cols="8" class="d-block">
                          <v-checkbox
                            v-model="form.atendeCriterioElegibilidade"
                          >
                            <template v-slot:label>
                              <div>
                                Declaro que permaneço atendendo aos critérios de
                                elegibilidade do programa de Adicional de
                                Valorização Profissional, conforme legislação em
                                vigor.
                              </div>
                            </template>
                          </v-checkbox>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card>
                </v-form>
              </v-tab-item>
            </v-tabs-items>
          </v-card>
        </v-col>
        <avp-buttons
          @click="cadastrarComprovacao()"
          @cancel="$back()"
          :cancelText="'Voltar'"
        ></avp-buttons>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { CadastrarValidacaoComprovacao } from "~/commons/classes";
export default {
  name: "CadastrarComprovacaoAcaoDeCapacitacaoView",
  data: () => ({
    menu: false,
    tab: "comprovacao-acao-de-capacitacao",
    lists: {
      instituicoes: [],
      tiposCapacitacao: [],
      eixosTematicos: [],
      trilhasAprendizagem: [],
    },
    form: CadastrarValidacaoComprovacao.Build(),
  }),
  watch: {
    "form.anexo": {
      handler(newValue, oldValue) {
        if (newValue && newValue.name.length > 100) {
          this.$notifyWarning(
            "O nome do anexo não pode ter mais que 100 caracteres!"
          );
          this.form.anexo = oldValue;
        }
      },
    },
  },
  methods: {
    cadastrarComprovacao() {
      const montarFormDataEnvio = () => {
        const formData = new FormData();
        formData.append("id", this.$route.params.id);
        formData.append("matricula", this.form.matricula);
        formData.append("nomeDeUsuario", this.$user.username);
        formData.append("instituicaoId", this.form.instituicaoId);
        this.form.nomeDeOutraInstituicao &&
          formData.append(
            "nomeDeOutraInstituicao",
            this.form.nomeDeOutraInstituicao
          );
        formData.append("tipoCapacitacaoId", this.form.tipoCapacitacaoId);
        formData.append("eixoTrilhaId", this.form.trilhaDeAprendizagemId);
        formData.append(
          "nomeDaAcaoDeCapacitacao",
          this.form.nomeDaAcaoDeCapacitacao
        );
        formData.append(
          "tipoDaCondicaoDeCapacitacao",
          this.form.tipoDaCondicaoDeCapacitacao
        );
        formData.append("cargaHoraria", this.form.cargaHoraria);
        formData.append(
          "dataInicioCapacitacao",
          this.$refs.dataInicioCapacitacao.computedDateFormattedCompleted
        );
        formData.append(
          "dataFimCapacitacao",
          this.$refs.dataFimCapacitacao.computedDateFormattedCompleted
        );
        formData.append("anexo", this.form.anexo);
        formData.append(
          "atendeCriterioElegibilidade",
          this.form.atendeCriterioElegibilidade
        );
        return formData;
      };

      if (this.$validarFormulario()) {
        if (!this.form.anexo.name) {
          return this.$notifyError("É obrigatório anexar um arquivo!");
        } else if (!this.form.atendeCriterioElegibilidade) {
          return this.$notifyError(
            "Só é possível salvar após informar que está atendendo aos Critérios de Elegibilidade!"
          );
        }
        const save = () => {
          return this.$route.params.id
            ? this.$editarComprovacaoAcaoCapacitacao(montarFormDataEnvio())
            : this.$cadastrarComprovacaoAcaoCapacitacao(montarFormDataEnvio());
        };
        save().then(({ data }) => {
          this.$back();
          this.$notifySuccess(data.mensagem);
        });
      }
    },
    setTrilhasAprendizagem(idEixo) {
      this.$listarTrilhaAprendizagemAcaoCapacitacao({
        params: { eixoId: idEixo },
      }).then(({ data }) => {
        this.lists.trilhasAprendizagem = data.trilhasDeAprendizagens;
        this.form.trilhaDeAprendizagemId = null;
      });
    },
  },
  mounted() {
    this.$listarInstituicoesAcaoCapacitacao().then(
      ({ data }) => (this.lists.instituicoes = data.instituicoes)
    );
    this.$listarTiposAcaoCapacitacao().then(
      ({ data }) => (this.lists.tiposCapacitacao = data.tiposCapacitacao)
    );
    this.$listarEixosTematicosAcaoCapacitacao().then(
      ({ data }) => (this.lists.eixosTematicos = data.eixosTematicos)
    );
    this.$listarAnoSemestrePorUsuario(this.$user.username).then(({ data }) => {
      this.form.ano = data.ano;
      this.form.semestre = data.semestre;
    });
    this.form.matricula = this.$route.params.matricula?.matricula;
    if (this.$route.params.id) {
      this.$obterDadosComprovacaoAcaoCapacitacao({
        params: {
          cpf: this.$user.username,
          id: this.$route.params.id,
          ano: this.$route.params.ano,
          semestre: this.$route.params.semestre,
        },
      }).then(({ data }) => {
        const comprovacao = data.comprovacao;
        this.form = new CadastrarValidacaoComprovacao({
          ano: comprovacao.ano,
          semestre: comprovacao.semestre,
          valid: true,
          instituicaoId: comprovacao.instituicao.id,
          matricula: comprovacao.matricula,
          nomeDaAcaoDeCapacitacao: comprovacao.nomeAcaoCapacitacao,
          nomeDeOutraInstituicao: comprovacao.instituicao.nomeDesOutro,
          tipoCapacitacaoId: comprovacao.tipoAcaoCapacitacao.id,
          eixoTematicoId: comprovacao.eixoTematico.id,
          trilhaDeAprendizagemId: comprovacao.eixoTrilha.id,
          tipoDaCondicaoDeCapacitacao: comprovacao.tipoDaCondicaoCapacitacao,
          cargaHoraria: comprovacao.cargaHoraria,
          dataInicioCapacitacao: comprovacao.mesAnoInicio,
          dataFimCapacitacao: comprovacao.mesAnoConclusao,
          anexo: null,
          atendeCriterioElegibilidade: true,
        });
        this.$listarTrilhaAprendizagemAcaoCapacitacao({
          params: { eixoId: comprovacao.eixoTrilha.id },
        }).then(({ data }) => (this.lists.trilhasAprendizagem = data.trilhasDeAprendizagens));
        this.$convertBase64ToFile(
          comprovacao.anexo.arquivo,
          comprovacao.anexo.descricao,
          comprovacao.anexo.tipoMime
        ).then((file) => (this.form.anexo = file));
      });
    }
  },
};
</script>
<style scoped>
.input {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}
input[type="file"] {
  align-items: flex-start;
  display: flex;
  flex: 1 1 auto;
  font-size: 16px;
  letter-spacing: normal;
  max-width: 100%;
  text-align: left;
  transition: border 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  border-radius: 4px;
  position: relative;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.87);
}
</style>