<template>
  <div class="text-center">
    <v-dialog persistent v-model="visible" width="1000">
      <v-card class="not-margin pt-0 pr-0 pl-0">
        <v-toolbar dark color="primary">
          <div class="text-center">
            <v-icon>mdi-account-edit</v-icon>
            Motivo da Não Validação da Comprovação da Ação de Capacitação
          </div>
        </v-toolbar>
        <div class="container dialog">
          <v-row class="d-flex justify-center mt-7">
            <v-col cols="6" class="d-block">
              <v-text-field
                label="Data da Validação da Comprovação da Ação de Capacitação"
                v-model="content.dataDaValidacao"
                readonly
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="6" class="d-block">
              <v-text-field
                label="Comprovação da Ação de Capacitação Validada?"
                :value="'Não Validada'"
                readonly
                outlined
                dense
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="d-block">
              <v-text-field
                label="Motivo"
                v-model="content.motivo"
                readonly
                outlined
                dense
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="d-block">
              <v-textarea
                label="Detalhe do Motivo"
                :value="content.detalheMotivo"
                readonly
                outlined
                dense
              ></v-textarea>
            </v-col>
          </v-row>
        </div>
        <div class="text-center">
          <v-btn color="primary" @click="$emit('onClose')">Fechar</v-btn>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { DialogProps } from '~/commons/classes';

export default {
  props: DialogProps.Build(),
};
</script>