<template>
  <div class="page">
    <v-container fluid>
      <avp-breadcrumbs></avp-breadcrumbs>
      <v-responsive max-width="800" class="mx-auto mb-4">
        <v-card class="overflow-y-auto show-on-print" max-height="600">
          <v-card-text
            class="font-weight-bold"
            v-html="termoEmHtml"
          ></v-card-text>
        </v-card>
        <v-container class="px-0" fluid>
          <v-checkbox v-model="checkbox" :disabled="usuario.termoLgpdAceito">
            <template v-slot:label>
              <div>
                Eu {{ usuario.nome }}, CPF Nº {{ $formatCpf(usuario.cpf) }}, matrícula(s)
                {{ matriculasFormatadas }}, lotado na(s)
                {{ lotacoesFormatadas }} concordo com os itens listados do
                <span class="blue--text">
                  Termo de consentimento do uso de dados pessoais.
                </span>
              </div>
            </template>
          </v-checkbox>
        </v-container>
        <avp-buttons v-if="!usuario.termoLgpdAceito"
          @click="aceitarTermo"
          @cancel="$toHome"
          :textAction="'Confirmar'"
          :disableAction="!checkbox"
        ></avp-buttons>
        <v-row class="d-block text-center btn-group" v-else>
          <v-col cols="12">
            <v-btn 
              color="primary"
              @click="$back()"
              >
              Voltar
            </v-btn>
          </v-col>
        </v-row>
       
      </v-responsive>
    </v-container>
  </div>
</template>
<script>
export default {
  name: "ConsentimentoDadosPessoaisView",
  computed: {
    matriculasFormatadas() {
      return this.usuario.vinculos.map((v) => v.matricula).toString();
    },
    lotacoesFormatadas() {
      return this.usuario.vinculos.map((v) => v.lotacao).toString();
    },
    termoEmHtml() {
      return (this.termo.conteudo || '')
      .replaceAll("{{DataAtual}}", this.$dayjs().format('DD/MM/YYYY HH:mm'))
      .replaceAll("{{NomeCompleto}}", this.usuario.nome)
      .replaceAll("{{Cpf}}", this.$formatCpf(this.usuario.cpf))
      .replaceAll("{{Email}}", this.usuario.emailPessoal)
      .replaceAll("{{TelefonePessoal}}", this.usuario.celular || this.usuario.telefone);
    }
  },
  data: () => ({
    checkbox: false,
    termo: {
      termoId: ``,
      conteudo: ``,
    },
    usuario: {
      cpf: ``,
      nome: ``,
      vinculos: [],
      termoLgpdAceito: null,
    },
  }),
  methods: {
    aceitarTermo() {
      this.$aceitarTermo({
        termoId: this.termo.id,
        cpf: this.$user.username,
        termoAceite: this.checkbox,
      }).then(({ data }) => {
        this.$atualizarCacheMenu().then(() => {
          this.$notifySuccess(data.mensagem);
          this.$toHome();
        });
      });
    },
  },
  mounted() {
    this.$obterTermo({ params: { nomeDoTermo: "lgpd" } }).then(({ data }) => {
      this.termo = data.termo;
      this.$obterDadosDoProfissional(this.$user.username).then(({ data }) => {
        this.usuario = data.profissional;
        this.checkbox = data.profissional.termoLgpdAceito;
      });
    });
  },
};
</script>