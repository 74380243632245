<template>
  <div class="page">
    <v-container fluid>
      <avp-breadcrumbs></avp-breadcrumbs>
      <v-row>
        <v-col cols="12" md="12" lg="12" xl="12">
          <v-card rounded="lg">
            <v-tabs v-model="form.tab" centered>
              <v-tabs-slider></v-tabs-slider>
              <v-tab href="#tab-dados-cadastrais">
                <v-icon class="p-2">mdi-account</v-icon>
                Dados Cadastrais
              </v-tab>
              <v-tab href="#tab-dados-profissionais">
                <v-icon>mdi-account-hard-hat</v-icon>
                Dados Profissionais
              </v-tab>
            </v-tabs>

            <v-tabs-items v-model="form.tab">
              <!-- Tab Dados Cadastrais -->
              <v-form
                ref="form"
                v-model="form.valid"
                class="px-10"
                autocomplete="off"
              >
                <v-tab-item 
                  value="tab-dados-cadastrais" 
                  eager
                >
                  <v-card rounded="lg">
                    <v-container>
                      <v-row>
                        <v-col cols="12" class="d-block">
                          <v-text-field
                            label="Nome"
                            hint="Nos diga como você se identifica"
                            v-model="dados.nome"
                            readonly
                            outlined
                            dense
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="6" class="d-block">
                          <v-text-field
                            label="CPF"
                            hint="Informe o seu CPF"
                            v-model="dados.cpf"
                            v-mask="'###.###.###-##'"
                            readonly
                            outlined
                            dense
                          ></v-text-field>
                        </v-col>
                        <v-col cols="6" class="d-block">
                          <v-text-field
                            label="E-mail Pessoal"
                            hint="Informe o seu e-mail pessoal"
                            v-model="dados.emailPessoal"
                            dense
                            outlined
                            :rules="[(v) => $required(v), (v) => $email(v)]"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="4" class="d-block">
                          <v-text-field
                            label="(DDD)"
                            v-mask="'(##)'"
                            hint="Informe o DDD do seu número de telefone"
                            v-model="dados.dddTelefone"
                            dense
                            outlined
                            :rules="[(v) => $required(v)]"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="8" class="d-block">
                          <v-text-field
                            label="Telefone"
                            v-mask="'####-####'"
                            hint="Informe o seu número de telefone"
                            v-model="dados.telefone"
                            dense
                            outlined
                            :rules="[(v) => $required(v)]"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="4" class="d-block">
                          <v-text-field
                            label="(DDD)"
                            v-mask="'(##)'"
                            hint="Informe o DDD do seu número de celular"
                            v-model="dados.dddCelular"
                            dense
                            outlined
                            :rules="[(v) => $required(v)]"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="8" class="d-block">
                          <v-text-field
                            label="Celular"
                            v-mask="'#-####-####'"
                            hint="Informe o seu número de celular"
                            v-model="dados.celular"
                            dense
                            outlined
                            :rules="[(v) => $required(v)]"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card>
                </v-tab-item>
                <!-- Tab Dados Profissionais -->
                <v-tab-item 
                  value="tab-dados-profissionais"
                  eager
                >
                  <v-card>
                    <v-container>
                      <v-row>
                        <v-col cols="12" class="d-block">
                          <v-text-field
                            label="E-mail Institucional"
                            hint="(Ex: email@educacao.pe.gov.br ou
                            email@adm.educacao.pe.gov.br ou
                            email@professor.educacao.pe.gov.br ou
                            email@esportes.pe.gov.br)"
                            v-model="dados.emailInstitucional"
                            outlined
                            dense
                            :rules="[
                              (v) => $required(v),
                              (v) =>
                                $email(
                                  v,
                                  [
                                    'educacao.pe.gov.br',
                                    'adm.educacao.pe.gov.br',
                                    'professor.educacao.pe.gov.br',
                                    'esportes.pe.gov.br',
                                  ],
                                  'Campo deve conter um email válido e com um domínio da secretaria!'
                                ),
                            ]"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="4" class="d-block">
                          <v-text-field
                            label="(DDD)"
                            v-mask="'(##)'"
                            hint="Informe o DDD do seu número de Telefone Institucional"
                            v-model="dados.dddTelefoneComercial"
                            dense
                            outlined
                            :rules="[(v) => $required(v)]"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="8" class="d-block">
                          <v-text-field
                            label="Telefone Institucional"
                            v-mask="'####-####'"
                            hint="Informe o seu número de Telefone Institucional"
                            v-model="dados.telefoneComercial"
                            dense
                            outlined
                            :rules="[(v) => $required(v)]"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" class="d-block">
                          <v-card>
                            <v-simple-table>
                              <template v-slot:default>
                                <thead>
                                  <tr>
                                    <th scope="col" class="text-center">
                                      Matrícula
                                    </th>
                                    <th scope="col" class="text-center">
                                      Cargo
                                    </th>
                                    <th scope="col" class="text-center">
                                      Lotação
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr
                                    v-for="vinculo in dados.vinculos"
                                    :key="vinculo.matricula"
                                  >
                                    <td class="text-center">
                                      {{ vinculo.matricula }}
                                    </td>
                                    <td class="text-center">
                                      {{ vinculo.cargo }}
                                    </td>
                                    <td class="text-center">
                                      {{ vinculo.lotacao }}
                                    </td>
                                  </tr>
                                </tbody>
                              </template>
                            </v-simple-table>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card>
                </v-tab-item>
              </v-form>
            </v-tabs-items>
          </v-card>
        </v-col>
        <avp-buttons
          @click="atualizarDadosDoProfissional"
          @cancel="$toHome"
        ></avp-buttons>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "AtualizarDadosCadastraisView",
  data: () => ({
    form: {
      valid: false,
      tab: "tab-dados-cadastrais",
    },
    dados: {
      nome: "",
      cpf: "",
      emailPessoal: "",
      dddTelefone: "",
      telefone: "",
      dddCelular: "",
      celular: "",
      emailInstitucional: "",
      dddComercial: "",
      telefoneComercial: "",
      vinculos: [],
    },
  }),
  methods: {
    montarDadosProfissional(profissional) {
      this.dados = {
        nome: profissional?.nome || null,
        cpf: profissional?.cpf || null,
        emailPessoal: profissional?.emailPessoal || null,
        dddTelefone: profissional?.dddTelefone || null,
        telefone: profissional?.telefone || null,
        dddCelular: profissional?.dddCelular || null,
        celular: profissional?.celular || null,
        emailInstitucional: profissional?.emailInstitucional || null,
        dddTelefoneComercial: profissional?.dddComercial || null,
        telefoneComercial: profissional?.telefoneComercial || null,
        vinculos: profissional?.vinculos || null,
      };
    },
    atualizarDadosDoProfissional() {
      if (this.$validarFormulario()) {
        const dadosEditados = {
          ...this.dados,
          cpf: this.$formatValue(this.dados.cpf),
          dddTelefone: this.$formatValue(this.dados.dddTelefone),
          telefone: this.$formatValue(this.dados.telefone),
          dddCelular: this.$formatValue(this.dados.dddCelular),
          celular: this.$formatValue(this.dados.celular),
          dddTelefoneComercial: this.$formatValue(
            this.dados.dddTelefoneComercial
          ),
          telefoneComercial: this.$formatValue(this.dados.telefoneComercial),
        };
        this.$atualizarDadosDoProfissional(dadosEditados).then(({ data }) => {
          this.$atualizarCacheMenu().then(() => {
            this.$notifySuccess(data.mensagem);
            this.$toHome();
          });
        });
      } else {
        this.$notifyError("Preencha todos os campos obrigatórios");
      }
    },
  },
  mounted() {
    this.$obterDadosDoProfissional(this.$user.username).then(({ data }) => {
      this.montarDadosProfissional(data.profissional);
    });
  },
};
</script>