var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "text-center"
  }, [_c('v-dialog', {
    attrs: {
      "persistent": "",
      "width": "500"
    },
    model: {
      value: _vm.visible,
      callback: function ($$v) {
        _vm.visible = $$v;
      },
      expression: "visible"
    }
  }, [_c('v-card', {
    staticClass: "not-margin pt-0 pr-0 pl-0"
  }, [_c('v-toolbar', {
    attrs: {
      "dark": "",
      "color": "primary"
    }
  }, [_c('div', {
    staticClass: "text-center"
  }, [_vm._v(" Selecione a matrícula que você deseja participar do programa neste semestre ")])]), _c('div', {
    staticClass: "container dialog"
  }, [_c('v-row', {
    staticClass: "d-flex justify-center mt-7"
  }, [_c('v-col', {
    attrs: {
      "cols": "8"
    }
  }, [_c('v-select', {
    attrs: {
      "outlined": "",
      "dense": "",
      "label": "Selecione a Matrícula",
      "items": _vm.content,
      "item-text": 'matricula',
      "item-value": 'id',
      "rules": [function (v) {
        return _vm.$required(v);
      }]
    },
    model: {
      value: _vm.matriculaSelecionada,
      callback: function ($$v) {
        _vm.matriculaSelecionada = $$v;
      },
      expression: "matriculaSelecionada"
    }
  })], 1)], 1)], 1), _c('v-card-actions', [_c('avp-buttons', {
    attrs: {
      "actionText": 'Selecionar'
    },
    on: {
      "click": function ($event) {
        return _vm.selecionarMatricula();
      },
      "cancel": function ($event) {
        return _vm.$emit('onClose');
      }
    }
  })], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }