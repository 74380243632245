<template>
  <div class="text-center">
    <v-dialog persistent v-model="visible" width="500">
      <v-card class="not-margin pt-0 pr-0 pl-0">
        <v-toolbar dark color="primary">
          <div class="text-center">
            Selecione a matrícula que você deseja participar do programa neste
            semestre
          </div>
        </v-toolbar>
        <div class="container dialog">
          <v-row class="d-flex justify-center mt-7">
            <v-col cols="8">
              <v-select
                outlined
                dense
                v-model="matriculaSelecionada"
                label="Selecione a Matrícula"
                :items="content"
                :item-text="'matricula'"
                :item-value="'id'"
                :rules="[(v) => $required(v)]"
              ></v-select> </v-col
          ></v-row>
        </div>
        <v-card-actions>
          <avp-buttons
            @click="selecionarMatricula()"
            @cancel="$emit('onClose')"
            :actionText="'Selecionar'"
          ></avp-buttons>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { DialogProps } from '~/commons/classes';

export default {
  props: DialogProps.Build(),
  data: () => ({
    matriculaSelecionada: null,
  }),
  methods: {
    selecionarMatricula() {
      if (!this.matriculaSelecionada) {
        return this.$notifyError("Selecione uma matrícula");
      } else {
        let matricula = this.content
          .filter((x) => x.id === this.matriculaSelecionada)
          .shift();
        this.$emit("onSelectValue", matricula);
      }
    },
  },
};
</script>