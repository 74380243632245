var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "text-center"
  }, [_c('v-dialog', {
    attrs: {
      "persistent": "",
      "width": "70%"
    },
    model: {
      value: _vm.visible,
      callback: function ($$v) {
        _vm.visible = $$v;
      },
      expression: "visible"
    }
  }, [_c('v-card', {
    staticClass: "not-margin pt-0 pr-0 pl-0"
  }, [_c('v-toolbar', {
    attrs: {
      "dark": "",
      "color": "primary"
    }
  }, [_c('div', {
    staticClass: "text-center"
  }, [_c('v-icon', [_vm._v("mdi-history")]), _vm._v(" Histórico de Comprovação da Ação de Capacitação ")], 1)]), _vm._l(_vm.content.comprovacoes, function (historico) {
    return _c('div', {
      key: historico.id,
      staticClass: "container dialog pt-4"
    }, [_c('v-row', [_c('v-col', {
      attrs: {
        "cols": "4"
      }
    }, [_c('v-text-field', {
      attrs: {
        "label": "Ano",
        "dense": "",
        "outlined": "",
        "value": historico.ano
      }
    })], 1), _c('v-col', {
      attrs: {
        "cols": "4"
      }
    }, [_c('v-text-field', {
      attrs: {
        "label": "Semestre",
        "dense": "",
        "outlined": "",
        "value": historico.semestre
      }
    })], 1), _c('v-col', {
      attrs: {
        "cols": "4"
      }
    }, [_c('v-text-field', {
      attrs: {
        "label": "Carga Horária Total",
        "dense": "",
        "outlined": "",
        "value": historico.cargaHorariaTotal
      }
    })], 1)], 1), _vm._l(historico.itens, function (dados) {
      return _c('v-card', {
        key: dados.caminhoArquivo,
        staticClass: "pt-8"
      }, [_c('v-row', [_c('v-container', [_c('v-row', [_c('v-col', {
        staticClass: "d-block",
        attrs: {
          "cols": "6"
        }
      }, [_c('v-text-field', {
        attrs: {
          "label": "Nome da Instituição",
          "readonly": "",
          "dense": "",
          "outlined": "",
          "value": dados.nomeInstituicao
        }
      })], 1), _c('v-col', {
        staticClass: "d-block",
        attrs: {
          "cols": "6"
        }
      }, [_c('v-text-field', {
        attrs: {
          "label": "Tipo da Ação de Capacitação",
          "readonly": "",
          "dense": "",
          "outlined": "",
          "value": dados.tipoAcaoCapacitacao
        }
      })], 1)], 1), _c('v-row', [_c('v-col', {
        staticClass: "d-block",
        attrs: {
          "cols": "6"
        }
      }, [_c('v-text-field', {
        attrs: {
          "label": "Eixo Temático",
          "readonly": "",
          "dense": "",
          "outlined": "",
          "value": dados.eixoTematico
        }
      })], 1), _c('v-col', {
        staticClass: "d-block",
        attrs: {
          "cols": "6"
        }
      }, [_c('v-text-field', {
        attrs: {
          "label": "Trilha de Aprendizagem",
          "readonly": "",
          "dense": "",
          "outlined": "",
          "value": dados.trilhaAprendizagem
        }
      })], 1)], 1), _c('v-row', [_c('v-col', {
        staticClass: "d-block",
        attrs: {
          "cols": "4"
        }
      }, [_c('v-text-field', {
        attrs: {
          "label": "Nome da ação de capacitação",
          "readonly": "",
          "dense": "",
          "outlined": "",
          "value": dados.nomeCapacitacao
        }
      })], 1), _c('v-col', {
        staticClass: "d-block",
        attrs: {
          "cols": "4"
        }
      }, [_c('v-text-field', {
        attrs: {
          "label": "Discente ou Docente",
          "readonly": "",
          "dense": "",
          "outlined": "",
          "value": dados.condicaoCapacitacao ? 'Discente' : 'Docente'
        }
      })], 1), _c('v-col', {
        staticClass: "d-block",
        attrs: {
          "cols": "4"
        }
      }, [_c('v-text-field', {
        attrs: {
          "label": "Carga Horária",
          "readonly": "",
          "dense": "",
          "outlined": "",
          "value": dados.cargaHoraria
        }
      })], 1)], 1), _c('v-row', [_c('v-col', {
        staticClass: "d-block",
        attrs: {
          "cols": "6"
        }
      }, [_c('v-text-field', {
        attrs: {
          "label": "Mês e Ano de Início",
          "readonly": "",
          "dense": "",
          "outlined": "",
          "value": dados.inicioCapacitacao
        }
      })], 1), _c('v-col', {
        staticClass: "d-block",
        attrs: {
          "cols": "6"
        }
      }, [_c('v-text-field', {
        attrs: {
          "label": "Mês e Ano de Conclusão",
          "readonly": "",
          "dense": "",
          "outlined": "",
          "value": dados.fimCapacitacao
        }
      })], 1)], 1), _c('v-row', [_c('v-col', {
        staticClass: "d-block",
        attrs: {
          "cols": "4"
        }
      }, [_c('v-text-field', {
        attrs: {
          "label": "Data/Hora da Inclusão da Ação de Capacitação",
          "readonly": "",
          "dense": "",
          "outlined": "",
          "value": dados.dataInclusaoCapacitacao
        }
      })], 1), _c('v-col', {
        staticClass: "d-block",
        attrs: {
          "cols": "4"
        }
      }, [_c('v-text-field', {
        attrs: {
          "label": "Data/Hora da Validação da Comprovação de Capacitação",
          "readonly": "",
          "dense": "",
          "outlined": "",
          "value": dados.dataValidacaoCapacitacao
        }
      })], 1), _c('v-col', {
        staticClass: "d-block",
        attrs: {
          "cols": "4"
        }
      }, [_c('v-text-field', {
        attrs: {
          "label": "Comprovação da Ação de Capacitação Validada?",
          "readonly": "",
          "dense": "",
          "outlined": "",
          "value": dados.comprovacaoValidada ? 'Sim' : 'Não'
        }
      })], 1)], 1), _c('v-row', {
        staticClass: "text-center"
      }, [_c('v-col', {
        staticClass: "d-block",
        attrs: {
          "cols": "12"
        }
      }, [_c('v-btn', {
        attrs: {
          "color": "primary"
        },
        on: {
          "click": function ($event) {
            return _vm.exibirPreviewArquivo(dados.caminhoArquivo);
          }
        }
      }, [_vm._v("Ver arquivo")])], 1), _c('avp-preview-file', {
        attrs: {
          "previewUrl": _vm.previewUrl,
          "visible": _vm.visibleDialogPreview,
          "path": dados.caminhoArquivo,
          "descricao": "Baixar arquivo"
        },
        on: {
          "onClose": function ($event) {
            return _vm.onCloseDialogPreview();
          }
        }
      })], 1)], 1)], 1)], 1);
    })], 2);
  }), _c('div', {
    staticClass: "text-center"
  }, [_c('v-btn', {
    attrs: {
      "color": "primary"
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('onClose');
      }
    }
  }, [_vm._v("Fechar")])], 1)], 2)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }