var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "page"
  }, [_c('v-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('avp-breadcrumbs'), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "12",
      "lg": "12",
      "xl": "12"
    }
  }, [_c('v-card', {
    attrs: {
      "rounded": "lg"
    }
  }, [_c('v-tabs', {
    attrs: {
      "centered": ""
    },
    model: {
      value: _vm.tab,
      callback: function ($$v) {
        _vm.tab = $$v;
      },
      expression: "tab"
    }
  }, [_c('v-tabs-slider'), _c('v-tab', {
    attrs: {
      "href": "#comprovacao-acao-de-capacitacao"
    }
  }, [_c('v-icon', {
    staticClass: "p-2"
  }, [_vm._v("mdi-eye")]), _vm._v(" Visualizar Comprovação de Ação de Capacitação ")], 1)], 1), _c('v-tabs-items', {
    model: {
      value: _vm.tab,
      callback: function ($$v) {
        _vm.tab = $$v;
      },
      expression: "tab"
    }
  }, [_c('v-tab-item', {
    attrs: {
      "value": "comprovacao-acao-de-capacitacao"
    }
  }, [_c('v-card', {
    attrs: {
      "rounded": "lg"
    }
  }, [_c('v-container', [_c('v-row', [_c('v-col', {
    staticClass: "d-block",
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "Ano",
      "readonly": ""
    },
    model: {
      value: _vm.form.ano,
      callback: function ($$v) {
        _vm.$set(_vm.form, "ano", $$v);
      },
      expression: "form.ano"
    }
  })], 1), _c('v-col', {
    staticClass: "d-block",
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "Semestre",
      "readonly": ""
    },
    model: {
      value: _vm.form.semestre,
      callback: function ($$v) {
        _vm.$set(_vm.form, "semestre", $$v);
      },
      expression: "form.semestre"
    }
  })], 1)], 1), _c('v-row', [_c('v-col', {
    staticClass: "d-block",
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "Nome da Instituição",
      "value": _vm.form.instituicao.nomeDesOutro ? _vm.form.instituicao.nomeDesOutro : _vm.form.instituicao.nome,
      "readonly": ""
    }
  })], 1), _c('v-col', {
    staticClass: "d-block",
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "Tipo da Ação de Capacitação",
      "readonly": ""
    },
    model: {
      value: _vm.form.tipoAcaoCapacitacao.nome,
      callback: function ($$v) {
        _vm.$set(_vm.form.tipoAcaoCapacitacao, "nome", $$v);
      },
      expression: "form.tipoAcaoCapacitacao.nome"
    }
  })], 1)], 1), _c('v-row', [_c('v-col', {
    staticClass: "d-block",
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "Eixo Temático",
      "readonly": ""
    },
    model: {
      value: _vm.form.eixoTematico.nome,
      callback: function ($$v) {
        _vm.$set(_vm.form.eixoTematico, "nome", $$v);
      },
      expression: "form.eixoTematico.nome"
    }
  })], 1), _c('v-col', {
    staticClass: "d-block",
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "Trilha de Aprendizagem",
      "readonly": ""
    },
    model: {
      value: _vm.form.trilhaDeAprendizagem.nome,
      callback: function ($$v) {
        _vm.$set(_vm.form.trilhaDeAprendizagem, "nome", $$v);
      },
      expression: "form.trilhaDeAprendizagem.nome"
    }
  })], 1)], 1), _c('v-row', [_c('v-col', {
    staticClass: "d-block",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "Nome da ação de capacitação",
      "readonly": ""
    },
    model: {
      value: _vm.form.nomeAcaoCapacitacao,
      callback: function ($$v) {
        _vm.$set(_vm.form, "nomeAcaoCapacitacao", $$v);
      },
      expression: "form.nomeAcaoCapacitacao"
    }
  })], 1)], 1), _c('v-row', [_c('v-col', {
    staticClass: "d-block",
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "Discente ou Docente",
      "readonly": ""
    },
    model: {
      value: _vm.form.tipoDaCondicaoCapacitacao,
      callback: function ($$v) {
        _vm.$set(_vm.form, "tipoDaCondicaoCapacitacao", $$v);
      },
      expression: "form.tipoDaCondicaoCapacitacao"
    }
  })], 1), _c('v-col', {
    staticClass: "d-block",
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "Carga Horária",
      "readonly": ""
    },
    model: {
      value: _vm.form.cargaHoraria,
      callback: function ($$v) {
        _vm.$set(_vm.form, "cargaHoraria", $$v);
      },
      expression: "form.cargaHoraria"
    }
  })], 1)], 1), _c('v-row', [_c('v-col', {
    staticClass: "d-block",
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "Mês e Ano de Início",
      "readonly": ""
    },
    model: {
      value: _vm.form.mesAnoInicio,
      callback: function ($$v) {
        _vm.$set(_vm.form, "mesAnoInicio", $$v);
      },
      expression: "form.mesAnoInicio"
    }
  })], 1), _c('v-col', {
    staticClass: "d-block",
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "Mês e Ano de Conclusão",
      "readonly": ""
    },
    model: {
      value: _vm.form.mesAnoConclusao,
      callback: function ($$v) {
        _vm.$set(_vm.form, "mesAnoConclusao", $$v);
      },
      expression: "form.mesAnoConclusao"
    }
  })], 1)], 1)], 1)], 1)], 1)], 1)], 1), _c('v-row', {
    staticClass: "text-center"
  }, [_c('v-col', {
    staticClass: "d-block",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-btn', {
    attrs: {
      "color": "primary",
      "outlined": ""
    },
    on: {
      "click": _vm.exibirPreviewArquivo
    }
  }, [_vm._v("Ver arquivo")])], 1), _c('avp-preview-file', {
    attrs: {
      "previewUrl": _vm.previewUrl,
      "visible": _vm.visibleDialogPreview,
      "path": _vm.form.anexo.path,
      "descricao": "Baixar arquivo"
    },
    on: {
      "onClose": function ($event) {
        return _vm.onCloseDialogPreview();
      }
    }
  })], 1), _c('br'), _c('div', {
    staticClass: "text-center"
  }, [_c('v-btn', {
    attrs: {
      "color": "primary",
      "outlined": ""
    },
    on: {
      "click": function ($event) {
        return _vm.$back();
      }
    }
  }, [_vm._v("Voltar")])], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }