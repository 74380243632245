<template>
  <div class="page">
    <v-container fluid>
      <avp-breadcrumbs></avp-breadcrumbs>
      <v-form ref="form">
        <v-row class="d-flex justify-center mt-3">
          <avp-select-situacao
            :cols="3"
            v-model="form.filters"
          ></avp-select-situacao>
          <avp-select-ano-semestre
            :sufix="'da Comprovação'"
            :cols="4"
            v-model="form.filters"
            all
          ></avp-select-ano-semestre>
        </v-row>
        <div class="text-center">
          <v-col cols="12">
            <avp-tooltip
              :text="'Buscar'"
              :icon="'mdi-magnify'"
              @click="buscarComprovacoes()"
            ></avp-tooltip>
            <avp-tooltip
              :text="'Limpar'"
              :icon="'mdi-eraser'"
              @click="$resetForm()"
            ></avp-tooltip>
            <avp-tooltip
              :text="'Incluir'"
              :icon="'mdi-plus'"
              @click="openDialogCadastrarComprovacao()"
            ></avp-tooltip>
            <avp-tooltip
              :text="'Consultar Histórico'"
              :icon="'mdi-history'"
              @click="openDialogHistoricoComprovacao()"
            ></avp-tooltip>
          </v-col>
        </div>
      </v-form>
      <v-data-table
        class="elevation-1 mt-5"
        :headers="headers"
        :items="form.lists.listComprovacoes"
        :items-per-page="10"
        :footer-props="$footerProps"
      >
        <template v-slot:[`item.ano`]="{ item }">
          {{ item.ano }}
        </template>
        <template v-slot:[`item.semestre`]="{ item }">
          {{ item.semestre }}
        </template>
        <template v-slot:[`item.nome`]="{ item }">
          {{ item.nome }}
        </template>
        <template v-slot:[`item.situacao`]="{ item }">
          {{ item.situacao.nome }}
        </template>
        <template v-slot:[`item.acoes`]="{ item }">
          <avp-tooltip
            v-if="item.situacao.id == 3 && item.cicloAtivo"
            :text="'Editar'"
            :icon="'mdi-pencil'"
            :small="true"
            @click="editarComprovacao(item)"
          ></avp-tooltip>
          <avp-tooltip
            v-if="item.situacao.id == 2 || !item.cicloAtivo"
            :text="'Visualizar'"
            :icon="'mdi-eye-outline'"
            :small="true"
            @click="visualizarComprovacao(item)"
          ></avp-tooltip>
          <avp-tooltip
            v-if="item.situacao.id == 1 && item.cicloAtivo"
            :text="'Visualizar o Motivo'"
            :icon="'mdi-file-eye-outline'"
            :small="true"
            @click="openDialogVisualizarMotivo(item)"
          ></avp-tooltip>
        </template>
      </v-data-table>
    </v-container>
    <ConfirmarMatriculaDialogComponent
      :visible="dialogConfirmarMatricula.visible"
      :content="dialogConfirmarMatricula.content"
      @onClose="dialogConfirmarMatricula.close()"
      @onSelectValue="incluirComprovacao($event)"
    ></ConfirmarMatriculaDialogComponent>
    <VisualizarMotivoNaoValidacaoDialogComponent
      :visible="dialogVisualizarMotivo.visible"
      :content="dialogVisualizarMotivo.content"
      @onClose="dialogVisualizarMotivo.close()"
    ></VisualizarMotivoNaoValidacaoDialogComponent>
    <VisualizarHistoricoDialogComponent
      :visible="dialogVisualizarHistorico.visible"
      :content="dialogVisualizarHistorico.content"
      @onClose="dialogVisualizarHistorico.close()"
    ></VisualizarHistoricoDialogComponent>
  </div>
</template>

<script>
import { Dialog, HistoricoComprovacao } from "~/commons/classes";
import ConfirmarMatriculaDialogComponent from "./dialogs/ConfirmarMatriculaDialogComponent.vue";
import VisualizarMotivoNaoValidacaoDialogComponent from "./dialogs/VisualizarMotivoNaoValidacaoDialogComponent.vue";
import VisualizarHistoricoDialogComponent from "./dialogs/VisualizarHistoricoDialogComponent.vue";

export default {
  name: "ListarComprovacaoAcaoDeCapacitacaoView",
  components: {
    ConfirmarMatriculaDialogComponent,
    VisualizarMotivoNaoValidacaoDialogComponent,
    VisualizarHistoricoDialogComponent,
  },
  data: () => ({
    dialogConfirmarMatricula: Dialog.Build(),
    dialogVisualizarMotivo: Dialog.Build(),
    dialogVisualizarHistorico: Dialog.Build(),
    form: {
      filters: {
        codigoSituacao: null,
        ano: null,
        semestre: null,
      },
      lists: {
        listComprovacoes: [],
      },
    },
    headers: [
      { text: "Ano", align: "center", value: "ano" },
      { text: "Semestre", align: "center", value: "semestre" },
      { text: "Nome da Ação de Capacitação", align: "center", value: "nome" },
      { text: "Situação", align: "center", value: "situacao" },
      { text: "Ações", align: "center", value: "acoes", sortable: false },
    ],
  }),
  methods: {
    incluirComprovacao(event) {
      this.$router.push({
        name: "Cadastrar Comprovação de Ação de Capacitação",
        params: { matricula: event },
      });
    },
    visualizarComprovacao(item) {
      this.$router.push({
        name: "Visualizar Comprovação de Ação de Capacitação",
        params: {
          id: item.id,
          ano: item.ano,
          semestre: item.semestre,
        },
      });
    },
    editarComprovacao(item) {
      this.$router.push({
        name: "Editar Comprovação de Ação de Capacitação",
        params: {
          id: item.id,
          ano: item.ano,
          semestre: item.semestre,
        },
      });
    },
    buscarComprovacoes() {
      this.$listarComprovacoesPorUsuario(
        this.$user.username,
        this.$montarParamsFilters(this.form.filters)
      ).then(({ data }) => (this.form.lists.listComprovacoes = data.comprovacoes));
    },
    openDialogCadastrarComprovacao() {
      this.$listarMatriculasPorUsuario(this.$user.username).then(({ data }) => {
        if (data.matriculas.length > 1) {
          this.dialogConfirmarMatricula.open(data.matriculas);
        } else this.incluirComprovacao(data.matriculas.shift());
      });
    },
    openDialogHistoricoComprovacao() {
      this.$listarHistoricoComprovacoes(
        this.$user.username,
        this.$montarParamsFilters(this.form.filters)
      ).then(({ data }) => {
        if (data.comprovacoes.length) {
          const contentHistoricoComprovacoes = new HistoricoComprovacao();
          contentHistoricoComprovacoes.comprovacoes = data.comprovacoes;
          this.dialogVisualizarHistorico.open(contentHistoricoComprovacoes);
        } else this.$notifySuccess("Nenhum histórico encontrado!");
      });
    },
    openDialogVisualizarMotivo(item) {
      this.dialogVisualizarMotivo.open(item.motivo);
    },
  },
  created() {
    // TODO: Essa consulta já é feita no componente <avp-select-ano-semestre>,
    // verificar se existe uma forma de não fazer novamente
    // talvez criar uma variavel no store para setar pois usa em 3 lugares fora o componente
    this.$listarAnoSemestreEdicoes().then(({ data }) => {
      const anoSemestreAtivo = data.anosSemestres.find((l) => l.ativo);
      this.$listarComprovacoesPorUsuario(this.$user.username, {
        params: {
          ano: anoSemestreAtivo.ano,
          semestre: anoSemestreAtivo.semestre,
        },
      }).then(({ data }) => (this.form.lists.listComprovacoes = data.comprovacoes));
    });
  },
};
</script>
