var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "page"
  }, [_c('v-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('avp-breadcrumbs'), _c('v-responsive', {
    staticClass: "mx-auto mb-4",
    attrs: {
      "max-width": "800"
    }
  }, [_c('v-card', {
    staticClass: "overflow-y-auto show-on-print",
    attrs: {
      "max-height": "600"
    }
  }, [_c('v-card-text', {
    staticClass: "font-weight-bold",
    domProps: {
      "innerHTML": _vm._s(_vm.termoEmHtml)
    }
  })], 1), _c('v-container', {
    staticClass: "px-0",
    attrs: {
      "fluid": ""
    }
  }, [_c('v-checkbox', {
    attrs: {
      "disabled": _vm.usuario.termoLgpdAceito
    },
    scopedSlots: _vm._u([{
      key: "label",
      fn: function () {
        return [_c('div', [_vm._v(" Eu " + _vm._s(_vm.usuario.nome) + ", CPF Nº " + _vm._s(_vm.$formatCpf(_vm.usuario.cpf)) + ", matrícula(s) " + _vm._s(_vm.matriculasFormatadas) + ", lotado na(s) " + _vm._s(_vm.lotacoesFormatadas) + " concordo com os itens listados do "), _c('span', {
          staticClass: "blue--text"
        }, [_vm._v(" Termo de consentimento do uso de dados pessoais. ")])])];
      },
      proxy: true
    }]),
    model: {
      value: _vm.checkbox,
      callback: function ($$v) {
        _vm.checkbox = $$v;
      },
      expression: "checkbox"
    }
  })], 1), !_vm.usuario.termoLgpdAceito ? _c('avp-buttons', {
    attrs: {
      "textAction": 'Confirmar',
      "disableAction": !_vm.checkbox
    },
    on: {
      "click": _vm.aceitarTermo,
      "cancel": _vm.$toHome
    }
  }) : _c('v-row', {
    staticClass: "d-block text-center btn-group"
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-btn', {
    attrs: {
      "color": "primary"
    },
    on: {
      "click": function ($event) {
        return _vm.$back();
      }
    }
  }, [_vm._v(" Voltar ")])], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }