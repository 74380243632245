var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "page"
  }, [_c('v-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('avp-breadcrumbs'), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "12",
      "lg": "12",
      "xl": "12"
    }
  }, [_c('v-card', {
    attrs: {
      "rounded": "lg"
    }
  }, [_c('v-tabs', {
    attrs: {
      "centered": ""
    },
    model: {
      value: _vm.form.tab,
      callback: function ($$v) {
        _vm.$set(_vm.form, "tab", $$v);
      },
      expression: "form.tab"
    }
  }, [_c('v-tabs-slider'), _c('v-tab', {
    attrs: {
      "href": "#tab-dados-cadastrais"
    }
  }, [_c('v-icon', {
    staticClass: "p-2"
  }, [_vm._v("mdi-account")]), _vm._v(" Dados Cadastrais ")], 1), _c('v-tab', {
    attrs: {
      "href": "#tab-dados-profissionais"
    }
  }, [_c('v-icon', [_vm._v("mdi-account-hard-hat")]), _vm._v(" Dados Profissionais ")], 1)], 1), _c('v-tabs-items', {
    model: {
      value: _vm.form.tab,
      callback: function ($$v) {
        _vm.$set(_vm.form, "tab", $$v);
      },
      expression: "form.tab"
    }
  }, [_c('v-form', {
    ref: "form",
    staticClass: "px-10",
    attrs: {
      "autocomplete": "off"
    },
    model: {
      value: _vm.form.valid,
      callback: function ($$v) {
        _vm.$set(_vm.form, "valid", $$v);
      },
      expression: "form.valid"
    }
  }, [_c('v-tab-item', {
    attrs: {
      "value": "tab-dados-cadastrais",
      "eager": ""
    }
  }, [_c('v-card', {
    attrs: {
      "rounded": "lg"
    }
  }, [_c('v-container', [_c('v-row', [_c('v-col', {
    staticClass: "d-block",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "Nome",
      "hint": "Nos diga como você se identifica",
      "readonly": "",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.dados.nome,
      callback: function ($$v) {
        _vm.$set(_vm.dados, "nome", $$v);
      },
      expression: "dados.nome"
    }
  })], 1)], 1), _c('v-row', [_c('v-col', {
    staticClass: "d-block",
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    directives: [{
      name: "mask",
      rawName: "v-mask",
      value: '###.###.###-##',
      expression: "'###.###.###-##'"
    }],
    attrs: {
      "label": "CPF",
      "hint": "Informe o seu CPF",
      "readonly": "",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.dados.cpf,
      callback: function ($$v) {
        _vm.$set(_vm.dados, "cpf", $$v);
      },
      expression: "dados.cpf"
    }
  })], 1), _c('v-col', {
    staticClass: "d-block",
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "E-mail Pessoal",
      "hint": "Informe o seu e-mail pessoal",
      "dense": "",
      "outlined": "",
      "rules": [function (v) {
        return _vm.$required(v);
      }, function (v) {
        return _vm.$email(v);
      }]
    },
    model: {
      value: _vm.dados.emailPessoal,
      callback: function ($$v) {
        _vm.$set(_vm.dados, "emailPessoal", $$v);
      },
      expression: "dados.emailPessoal"
    }
  })], 1)], 1), _c('v-row', [_c('v-col', {
    staticClass: "d-block",
    attrs: {
      "cols": "4"
    }
  }, [_c('v-text-field', {
    directives: [{
      name: "mask",
      rawName: "v-mask",
      value: '(##)',
      expression: "'(##)'"
    }],
    attrs: {
      "label": "(DDD)",
      "hint": "Informe o DDD do seu número de telefone",
      "dense": "",
      "outlined": "",
      "rules": [function (v) {
        return _vm.$required(v);
      }]
    },
    model: {
      value: _vm.dados.dddTelefone,
      callback: function ($$v) {
        _vm.$set(_vm.dados, "dddTelefone", $$v);
      },
      expression: "dados.dddTelefone"
    }
  })], 1), _c('v-col', {
    staticClass: "d-block",
    attrs: {
      "cols": "8"
    }
  }, [_c('v-text-field', {
    directives: [{
      name: "mask",
      rawName: "v-mask",
      value: '####-####',
      expression: "'####-####'"
    }],
    attrs: {
      "label": "Telefone",
      "hint": "Informe o seu número de telefone",
      "dense": "",
      "outlined": "",
      "rules": [function (v) {
        return _vm.$required(v);
      }]
    },
    model: {
      value: _vm.dados.telefone,
      callback: function ($$v) {
        _vm.$set(_vm.dados, "telefone", $$v);
      },
      expression: "dados.telefone"
    }
  })], 1)], 1), _c('v-row', [_c('v-col', {
    staticClass: "d-block",
    attrs: {
      "cols": "4"
    }
  }, [_c('v-text-field', {
    directives: [{
      name: "mask",
      rawName: "v-mask",
      value: '(##)',
      expression: "'(##)'"
    }],
    attrs: {
      "label": "(DDD)",
      "hint": "Informe o DDD do seu número de celular",
      "dense": "",
      "outlined": "",
      "rules": [function (v) {
        return _vm.$required(v);
      }]
    },
    model: {
      value: _vm.dados.dddCelular,
      callback: function ($$v) {
        _vm.$set(_vm.dados, "dddCelular", $$v);
      },
      expression: "dados.dddCelular"
    }
  })], 1), _c('v-col', {
    staticClass: "d-block",
    attrs: {
      "cols": "8"
    }
  }, [_c('v-text-field', {
    directives: [{
      name: "mask",
      rawName: "v-mask",
      value: '#-####-####',
      expression: "'#-####-####'"
    }],
    attrs: {
      "label": "Celular",
      "hint": "Informe o seu número de celular",
      "dense": "",
      "outlined": "",
      "rules": [function (v) {
        return _vm.$required(v);
      }]
    },
    model: {
      value: _vm.dados.celular,
      callback: function ($$v) {
        _vm.$set(_vm.dados, "celular", $$v);
      },
      expression: "dados.celular"
    }
  })], 1)], 1)], 1)], 1)], 1), _c('v-tab-item', {
    attrs: {
      "value": "tab-dados-profissionais",
      "eager": ""
    }
  }, [_c('v-card', [_c('v-container', [_c('v-row', [_c('v-col', {
    staticClass: "d-block",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "E-mail Institucional",
      "hint": "(Ex: email@educacao.pe.gov.br ou\n                            email@adm.educacao.pe.gov.br ou\n                            email@professor.educacao.pe.gov.br ou\n                            email@esportes.pe.gov.br)",
      "outlined": "",
      "dense": "",
      "rules": [function (v) {
        return _vm.$required(v);
      }, function (v) {
        return _vm.$email(v, ['educacao.pe.gov.br', 'adm.educacao.pe.gov.br', 'professor.educacao.pe.gov.br', 'esportes.pe.gov.br'], 'Campo deve conter um email válido e com um domínio da secretaria!');
      }]
    },
    model: {
      value: _vm.dados.emailInstitucional,
      callback: function ($$v) {
        _vm.$set(_vm.dados, "emailInstitucional", $$v);
      },
      expression: "dados.emailInstitucional"
    }
  })], 1)], 1), _c('v-row', [_c('v-col', {
    staticClass: "d-block",
    attrs: {
      "cols": "4"
    }
  }, [_c('v-text-field', {
    directives: [{
      name: "mask",
      rawName: "v-mask",
      value: '(##)',
      expression: "'(##)'"
    }],
    attrs: {
      "label": "(DDD)",
      "hint": "Informe o DDD do seu número de Telefone Institucional",
      "dense": "",
      "outlined": "",
      "rules": [function (v) {
        return _vm.$required(v);
      }]
    },
    model: {
      value: _vm.dados.dddTelefoneComercial,
      callback: function ($$v) {
        _vm.$set(_vm.dados, "dddTelefoneComercial", $$v);
      },
      expression: "dados.dddTelefoneComercial"
    }
  })], 1), _c('v-col', {
    staticClass: "d-block",
    attrs: {
      "cols": "8"
    }
  }, [_c('v-text-field', {
    directives: [{
      name: "mask",
      rawName: "v-mask",
      value: '####-####',
      expression: "'####-####'"
    }],
    attrs: {
      "label": "Telefone Institucional",
      "hint": "Informe o seu número de Telefone Institucional",
      "dense": "",
      "outlined": "",
      "rules": [function (v) {
        return _vm.$required(v);
      }]
    },
    model: {
      value: _vm.dados.telefoneComercial,
      callback: function ($$v) {
        _vm.$set(_vm.dados, "telefoneComercial", $$v);
      },
      expression: "dados.telefoneComercial"
    }
  })], 1)], 1), _c('v-row', [_c('v-col', {
    staticClass: "d-block",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-card', [_c('v-simple-table', {
    scopedSlots: _vm._u([{
      key: "default",
      fn: function () {
        return [_c('thead', [_c('tr', [_c('th', {
          staticClass: "text-center",
          attrs: {
            "scope": "col"
          }
        }, [_vm._v(" Matrícula ")]), _c('th', {
          staticClass: "text-center",
          attrs: {
            "scope": "col"
          }
        }, [_vm._v(" Cargo ")]), _c('th', {
          staticClass: "text-center",
          attrs: {
            "scope": "col"
          }
        }, [_vm._v(" Lotação ")])])]), _c('tbody', _vm._l(_vm.dados.vinculos, function (vinculo) {
          return _c('tr', {
            key: vinculo.matricula
          }, [_c('td', {
            staticClass: "text-center"
          }, [_vm._v(" " + _vm._s(vinculo.matricula) + " ")]), _c('td', {
            staticClass: "text-center"
          }, [_vm._v(" " + _vm._s(vinculo.cargo) + " ")]), _c('td', {
            staticClass: "text-center"
          }, [_vm._v(" " + _vm._s(vinculo.lotacao) + " ")])]);
        }), 0)];
      },
      proxy: true
    }])
  })], 1)], 1)], 1)], 1)], 1)], 1)], 1)], 1)], 1)], 1), _c('avp-buttons', {
    on: {
      "click": _vm.atualizarDadosDoProfissional,
      "cancel": _vm.$toHome
    }
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }