<template>
  <div class="text-center">
    <v-dialog persistent v-model="visible" width="70%">
      <v-card class="not-margin pt-0 pr-0 pl-0">
        <v-toolbar dark color="primary">
          <div class="text-center">
            <v-icon>mdi-history</v-icon>
            Histórico de Comprovação da Ação de Capacitação
          </div>
        </v-toolbar>
        <div
          class="container dialog pt-4"
          v-for="historico in content.comprovacoes"
          :key="historico.id"
        >
          <v-row>
            <v-col cols="4">
              <v-text-field
                label="Ano"
                dense
                outlined
                :value="historico.ano"
              ></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field
                label="Semestre"
                dense
                outlined
                :value="historico.semestre"
              ></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field
                label="Carga Horária Total"
                dense
                outlined
                :value="historico.cargaHorariaTotal"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-card
            class="pt-8"
            v-for="dados in historico.itens"
            :key="dados.caminhoArquivo"
          >
            <v-row>
              <v-container>
                <v-row>
                  <v-col cols="6" class="d-block">
                    <v-text-field
                      label="Nome da Instituição"
                      readonly
                      dense
                      outlined
                      :value="dados.nomeInstituicao"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6" class="d-block">
                    <v-text-field
                      label="Tipo da Ação de Capacitação"
                      readonly
                      dense
                      outlined
                      :value="dados.tipoAcaoCapacitacao"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6" class="d-block">
                    <v-text-field
                      label="Eixo Temático"
                      readonly
                      dense
                      outlined
                      :value="dados.eixoTematico"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6" class="d-block">
                    <v-text-field
                      label="Trilha de Aprendizagem"
                      readonly
                      dense
                      outlined
                      :value="dados.trilhaAprendizagem"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="4" class="d-block">
                    <v-text-field
                      label="Nome da ação de capacitação"
                      readonly
                      dense
                      outlined
                      :value="dados.nomeCapacitacao"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="4" class="d-block">
                    <v-text-field
                      label="Discente ou Docente"
                      readonly
                      dense
                      outlined
                      :value="dados.condicaoCapacitacao ? 'Discente' : 'Docente'"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="4" class="d-block">
                    <v-text-field
                      label="Carga Horária"
                      readonly
                      dense
                      outlined
                      :value="dados.cargaHoraria"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6" class="d-block">
                    <v-text-field
                      label="Mês e Ano de Início"
                      readonly
                      dense
                      outlined
                      :value="dados.inicioCapacitacao"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6" class="d-block">
                    <v-text-field
                      label="Mês e Ano de Conclusão"
                      readonly
                      dense
                      outlined
                      :value="dados.fimCapacitacao"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="4" class="d-block">
                    <v-text-field
                      label="Data/Hora da Inclusão da Ação de Capacitação"
                      readonly
                      dense
                      outlined
                      :value="dados.dataInclusaoCapacitacao"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="4" class="d-block">
                    <v-text-field
                      label="Data/Hora da Validação da Comprovação de Capacitação"
                      readonly
                      dense
                      outlined
                      :value="dados.dataValidacaoCapacitacao"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="4" class="d-block">
                    <v-text-field
                      label="Comprovação da Ação de Capacitação Validada?"
                      readonly
                      dense
                      outlined
                      :value="dados.comprovacaoValidada ? 'Sim': 'Não'"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row class="text-center">
                  <v-col cols="12" class="d-block">
                    <v-btn color="primary" @click="exibirPreviewArquivo(dados.caminhoArquivo)"
                      >Ver arquivo</v-btn
                    >
                  </v-col>

                  <avp-preview-file
                    :previewUrl="previewUrl"
                    :visible="visibleDialogPreview"
                    :path="dados.caminhoArquivo"
                    descricao="Baixar arquivo"
                    @onClose="onCloseDialogPreview()"
                  >
                  </avp-preview-file>
                </v-row>
              </v-container>
            </v-row>
          </v-card>
        </div>
        <div class="text-center">
          <v-btn color="primary" @click="$emit('onClose')">Fechar</v-btn>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { DialogProps, HistoricoComprovacao } from "~/commons/classes";

export default {
  data: () => ({
    visibleDialogPreview: false,
    previewUrl: undefined,
  }),
  props: new DialogProps(HistoricoComprovacao),
  methods: {
    exibirPreviewArquivo(dados) {
      this.showPageLoading();
      this.$realizarDownloadPorPath({
        params: {
          path: dados,
          nomeDeUsuario: this.$user.username,
        },
        responseType: "blob",
      }).then(({ data }) => {
        this.previewUrl = URL.createObjectURL(data);
        this.visibleDialogPreview = true;
      });
      this.hidePageLoading();
    },
    onCloseDialogPreview() {
      this.visibleDialogPreview = false;
    },
  },
};
</script>

<style lang="scss">
div.container.dialog {
  padding-top: 3em !important;
}

div.content {
  padding-top: 2em !important;
}
</style>