var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "text-center"
  }, [_c('v-dialog', {
    attrs: {
      "persistent": "",
      "width": "1000"
    },
    model: {
      value: _vm.visible,
      callback: function ($$v) {
        _vm.visible = $$v;
      },
      expression: "visible"
    }
  }, [_c('v-card', {
    staticClass: "not-margin pt-0 pr-0 pl-0"
  }, [_c('v-toolbar', {
    attrs: {
      "dark": "",
      "color": "primary"
    }
  }, [_c('div', {
    staticClass: "text-center"
  }, [_c('v-icon', [_vm._v("mdi-account-edit")]), _vm._v(" Motivo da Não Validação da Comprovação da Ação de Capacitação ")], 1)]), _c('div', {
    staticClass: "container dialog"
  }, [_c('v-row', {
    staticClass: "d-flex justify-center mt-7"
  }, [_c('v-col', {
    staticClass: "d-block",
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "Data da Validação da Comprovação da Ação de Capacitação",
      "readonly": "",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.content.dataDaValidacao,
      callback: function ($$v) {
        _vm.$set(_vm.content, "dataDaValidacao", $$v);
      },
      expression: "content.dataDaValidacao"
    }
  })], 1), _c('v-col', {
    staticClass: "d-block",
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "Comprovação da Ação de Capacitação Validada?",
      "value": 'Não Validada',
      "readonly": "",
      "outlined": "",
      "dense": ""
    }
  })], 1)], 1), _c('v-row', [_c('v-col', {
    staticClass: "d-block",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "Motivo",
      "readonly": "",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.content.motivo,
      callback: function ($$v) {
        _vm.$set(_vm.content, "motivo", $$v);
      },
      expression: "content.motivo"
    }
  })], 1)], 1), _c('v-row', [_c('v-col', {
    staticClass: "d-block",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-textarea', {
    attrs: {
      "label": "Detalhe do Motivo",
      "value": _vm.content.detalheMotivo,
      "readonly": "",
      "outlined": "",
      "dense": ""
    }
  })], 1)], 1)], 1), _c('div', {
    staticClass: "text-center"
  }, [_c('v-btn', {
    attrs: {
      "color": "primary"
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('onClose');
      }
    }
  }, [_vm._v("Fechar")])], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }