var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "page"
  }, [_c('v-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('avp-breadcrumbs'), _c('v-form', {
    ref: "form"
  }, [_c('v-row', {
    staticClass: "d-flex justify-center mt-3"
  }, [_c('avp-select-situacao', {
    attrs: {
      "cols": 3
    },
    model: {
      value: _vm.form.filters,
      callback: function ($$v) {
        _vm.$set(_vm.form, "filters", $$v);
      },
      expression: "form.filters"
    }
  }), _c('avp-select-ano-semestre', {
    attrs: {
      "sufix": 'da Comprovação',
      "cols": 4,
      "all": ""
    },
    model: {
      value: _vm.form.filters,
      callback: function ($$v) {
        _vm.$set(_vm.form, "filters", $$v);
      },
      expression: "form.filters"
    }
  })], 1), _c('div', {
    staticClass: "text-center"
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('avp-tooltip', {
    attrs: {
      "text": 'Buscar',
      "icon": 'mdi-magnify'
    },
    on: {
      "click": function ($event) {
        return _vm.buscarComprovacoes();
      }
    }
  }), _c('avp-tooltip', {
    attrs: {
      "text": 'Limpar',
      "icon": 'mdi-eraser'
    },
    on: {
      "click": function ($event) {
        return _vm.$resetForm();
      }
    }
  }), _c('avp-tooltip', {
    attrs: {
      "text": 'Incluir',
      "icon": 'mdi-plus'
    },
    on: {
      "click": function ($event) {
        return _vm.openDialogCadastrarComprovacao();
      }
    }
  }), _c('avp-tooltip', {
    attrs: {
      "text": 'Consultar Histórico',
      "icon": 'mdi-history'
    },
    on: {
      "click": function ($event) {
        return _vm.openDialogHistoricoComprovacao();
      }
    }
  })], 1)], 1)], 1), _c('v-data-table', {
    staticClass: "elevation-1 mt-5",
    attrs: {
      "headers": _vm.headers,
      "items": _vm.form.lists.listComprovacoes,
      "items-per-page": 10,
      "footer-props": _vm.$footerProps
    },
    scopedSlots: _vm._u([{
      key: `item.ano`,
      fn: function (_ref) {
        var item = _ref.item;
        return [_vm._v(" " + _vm._s(item.ano) + " ")];
      }
    }, {
      key: `item.semestre`,
      fn: function (_ref2) {
        var item = _ref2.item;
        return [_vm._v(" " + _vm._s(item.semestre) + " ")];
      }
    }, {
      key: `item.nome`,
      fn: function (_ref3) {
        var item = _ref3.item;
        return [_vm._v(" " + _vm._s(item.nome) + " ")];
      }
    }, {
      key: `item.situacao`,
      fn: function (_ref4) {
        var item = _ref4.item;
        return [_vm._v(" " + _vm._s(item.situacao.nome) + " ")];
      }
    }, {
      key: `item.acoes`,
      fn: function (_ref5) {
        var item = _ref5.item;
        return [item.situacao.id == 3 && item.cicloAtivo ? _c('avp-tooltip', {
          attrs: {
            "text": 'Editar',
            "icon": 'mdi-pencil',
            "small": true
          },
          on: {
            "click": function ($event) {
              return _vm.editarComprovacao(item);
            }
          }
        }) : _vm._e(), item.situacao.id == 2 || !item.cicloAtivo ? _c('avp-tooltip', {
          attrs: {
            "text": 'Visualizar',
            "icon": 'mdi-eye-outline',
            "small": true
          },
          on: {
            "click": function ($event) {
              return _vm.visualizarComprovacao(item);
            }
          }
        }) : _vm._e(), item.situacao.id == 1 && item.cicloAtivo ? _c('avp-tooltip', {
          attrs: {
            "text": 'Visualizar o Motivo',
            "icon": 'mdi-file-eye-outline',
            "small": true
          },
          on: {
            "click": function ($event) {
              return _vm.openDialogVisualizarMotivo(item);
            }
          }
        }) : _vm._e()];
      }
    }], null, true)
  })], 1), _c('ConfirmarMatriculaDialogComponent', {
    attrs: {
      "visible": _vm.dialogConfirmarMatricula.visible,
      "content": _vm.dialogConfirmarMatricula.content
    },
    on: {
      "onClose": function ($event) {
        return _vm.dialogConfirmarMatricula.close();
      },
      "onSelectValue": function ($event) {
        return _vm.incluirComprovacao($event);
      }
    }
  }), _c('VisualizarMotivoNaoValidacaoDialogComponent', {
    attrs: {
      "visible": _vm.dialogVisualizarMotivo.visible,
      "content": _vm.dialogVisualizarMotivo.content
    },
    on: {
      "onClose": function ($event) {
        return _vm.dialogVisualizarMotivo.close();
      }
    }
  }), _c('VisualizarHistoricoDialogComponent', {
    attrs: {
      "visible": _vm.dialogVisualizarHistorico.visible,
      "content": _vm.dialogVisualizarHistorico.content
    },
    on: {
      "onClose": function ($event) {
        return _vm.dialogVisualizarHistorico.close();
      }
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }